import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import imageFourOhFour from '../assets/images/404.jpg'

const NotFoundPage = () => (
  <PageFourOhFour to="/">
    <span>Take me back</span>
  </PageFourOhFour>
)

export default NotFoundPage

const PageFourOhFour = styled(Link)`
  background: url("${imageFourOhFour}") no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  display: block;
  position: relative;

  > span {
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 4px;
    position: absolute;
    text-align: center;
    display: block;
    padding: 12px;
    left: 50%;
    bottom: 300px;
    transform: translate3d(-50%, 0, 0);
    color: #535353;
    border: 2px solid #535353;
  }
`
